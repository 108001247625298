@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
}

.container{
    max-width: 1170px;
    /* padding: 0 10px; */
}

.pac-container{
    width: 50%;
    margin-top: 10px;
  }
  
  .pac-container .pac-item {
    white-space: normal;
    word-break: break-word;
    text-align: left;
    font-size: 15px;
  
    border-radius: 5px;
    font-size: 15px;
  }
  .pac-icon {
    display: none !important;
  }


  .trustedBg{
    background-image: url("./assets/images/trusted\ bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    position: static;
  }